import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Statement from "../components/statement"
import PostCard from "../components/postCard"

import SMT from "../components/socialMediaTray"

import indexStyles from "./styles/index.module.scss"

const IndexPage = ({ data }) =>{
  const{ allMarkdownRemark } = data;
  const{ siteMetadata } = data.site;
  
  return(
    <Layout>
      <GatsbySeo 
        description="Simons's Blogchain landing site"
        openGraph={{
          url:'https://www.simon.sekavcnik.com',
          title: "Simon's Blogchain",
          description: "Simon's Blogchain landing page",
          site_name: siteMetadata.title
        }}
        twitter={{
          title:"Simon's Blogchain",
          description:"Landing page to Simon's Blogchain."
        }}
      />
      <Statement/>
      <SMT />
      <h2 className={indexStyles.recentPosts}> Recent posts </h2>
      <hr className={indexStyles.divide} />
      <div className={indexStyles.cardContainer}>
      {allMarkdownRemark.edges.map((post,index) =>{
        return(
          <PostCard 
          key={"postCard"+index}
          title={post.node.frontmatter.title}
          date={post.node.frontmatter.date}
          excerpt={post.node.frontmatter.abstract}
          to={post.node.fields.slug}
          />
        )
      })}
      </div>
    </Layout>
  )
}
export default IndexPage

export const query = graphql`
  query MyQuery {
    site {
      siteMetadata{
        siteUrl,
        title,
        image,
        description,
        author,
        twitter
      }
    }
    allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
  	  limit:5
    ) {
      edges {
        node {
          frontmatter{
            title,
            date(formatString: "YYYY / MMM / DD"),
            abstract
          }
          fields{
            slug
          }
        }
      }
    }
  file(relativePath: {eq: "ogimage.png"}) {
    childImageSharp {
      id
      original {
        width
        height
        src
      }
    }
  }
  }
`
