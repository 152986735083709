import React from "react"

import smtStyles from "./styles/smt.module.scss"


// IMAGES 
import twitterWhite from "../images/twitter_white.png";
import twitterBlack from "../images/twitter_black.png";
import mindsWhite   from "../images/minds_white.png";
import mindsBlack   from "../images/minds_black.png";
import linkedinBlack from "../images/linkedin_black.png";
import linkedinWhite from "../images/linkedin_white.png";

import linkedinGeneric from "../images/linkedin_generic.png";
import twitterGeneric from "../images/twitter_generic.png";
import mindsGeneric from "../images/minds_generic.png";


const socialLinks = [
  { name: "twitter",
    link: "https://twitter.com/SSekavcnik",
    light: twitterBlack,
    dark: twitterWhite,
    generic: twitterGeneric,
    id: "social-icon-1"
  },
  { name: "minds",
    link: "https://www.minds.com/simonsekavcnik",
    light: mindsBlack,
    dark: mindsWhite,
    generic: mindsGeneric,
    id: "social-icon-2"
  },
  { name: "linkedin",
    link:"https://www.linkedin.com/in/simonsekavcnik/",
    light: linkedinBlack,
    dark: linkedinWhite,
    generic: linkedinGeneric,
    id: "social-icon-3"
  }
]




const socialLinksRendered = socialLinks.map((link)=>{
  return(
    <li key={link.id}>
      <a href={link.link} rel="noopener noreferrer" target="_blank">
        <img src={link.generic} alt={link.name+"icon"}/>
      </a>
    </li>
  )
})

const SMT = () => {
  
  return(
    <div className={smtStyles.container}>
      <ul>
       {socialLinksRendered}
      </ul>
    </div>
  );
}

export default SMT;


