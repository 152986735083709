import React from "react"
import { Link } from "gatsby"

import "./styles/global.scss"
import postCardStyle from "./styles/postcard.module.scss"

const postCard = ({ children, isIndex, title, date, excerpt, to }) => {
  excerpt = (excerpt) ? excerpt : "";
  const length = 60;
  const trimedExcerpt = excerpt.length > length ? 
                    excerpt.substring(0, length - 3) + "..." : 
                    excerpt;
  return (
    <Link to={to} className={postCardStyle.link}>
    <div className={postCardStyle.container}>
      <div className={postCardStyle.date}>
        <p> {date} </p>
      </div>
    <h3> {title}</h3>
    <p> {trimedExcerpt} </p>
    </div>
    </Link>
  )
}

export default postCard
