
import React from "react"

import "./styles/global.scss"
import statementStyles from "./styles/statement.module.scss"




const Statement = ({ children, isIndex }) => {
  return (
    <div className={statementStyles.statement}>
    <h1>Simon's Blogchain</h1>
    <hr align="center" />
    <div className="profileContainer">
    </div>
    <p>
    This is my personal blog. 
    Here I mostly write about stuff I'm interested in, which means
    opensource software, decentralization and random topics, that
    might interest me at that moment.
    If you like any of listed topics you might want to stick around.
    </p>
    </div>
  )
}

export default Statement
